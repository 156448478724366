import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { logoutUser, signin } from "../../apis/users";


export default function AuthProvider ({ children }) {
    const userConnect = useLoaderData();
    const [user, setUser] = useState(userConnect);

    /* Connexion d'un utilisateur */
    async function login (values) {
        const newUser = await signin(values);
        setTimeout(() => {
            setUser(newUser);
        }, 3000);

    }

    /* Déconnexion d'un utilisateur */
    async function logout () {
        await logoutUser();
        setUser("");
    }

    return (
        <AuthContext.Provider value={{
            user,
            login,
            logout,
            setUser
        }}>
            {children}
        </AuthContext.Provider>
    );
}