import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";


/* Route protégée qui redirige un utilsateur vers la page login si il essaie d'accèder à une page qui nécessite d'être connecté */
export function ProtectedRouteLogin ({ children }) {
    const { user } = useContext(AuthContext);
    return user ? children : <Navigate to="/login" />;
}

/* Route protégée qui redirige un utilisateur vers la page profile si il essaie d'accèder à des pages accessible uniquement en étant déconnecté (login/register) */
export function ProtectedRouteForms ({ children }) {
    const { user } = useContext(AuthContext);
    return user ? <Navigate to="/profile" /> : children;
}

/* Route protégée qui redirige un utilisateur vers la page login si il essaie d'accèder à une page réservé aux administrateurs */
export function ProtectedRouteAdmin ({ children }) {
    const { user } = useContext(AuthContext);
    return user && user.role === 1 ? children : <Navigate to="/login" />;
}