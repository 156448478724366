// const API_USERS = "http://localhost:8000/api/users";
const API_USERS = "https://back.littlekowicz.fr/api/users";

/* Créer et insérer un nouvel utilisateur en BDD */
export async function createUser (newUser) {
    const response = await fetch(`${ API_USERS }/register`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
    });
    const backResponse = await response.json();
    if (response.ok) {
        return (backResponse);
    } else {
        if (backResponse) {
            throw backResponse;
        } else {
            throw new Error("Erreur lors de la création du nouveau compte utilisateur.");
        }
    }
}

/* Supprimer un compte utilisateur */
export async function deleteUser (idUser) {
    try {
        const response = await fetch(`${ API_USERS }/deleteUser`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ idUser })
        });
        if (response.ok) {
            return response;
        }
    } catch (error) {
        console.error("Erreur lors de la suppression du compte utilisateur.");
        return ("Erreur lors de la suppression du compte utilisateur.");
    }
}

/* Connecter un utilisateur */
export async function signin (values) {
    const response = await fetch(`${ API_USERS }/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
        credentials: 'include',
    });
    const backResponse = await response.json();
    if (response.ok) {
        return backResponse;
    } else {
        if (backResponse) {
            throw backResponse;
        } else {
            throw new Error("Error API login");
        }
    }
}

/* Déconnecter un utilisateur */
export async function logoutUser () {
    await fetch(`${ API_USERS }/logout`, {
        credentials: 'include'
    });

}

/* Vérifier si un utilisateur est connecté */
export async function getConnectedUser () {
    const response = await fetch(`${ API_USERS }/userConnected`, {
        credentials: 'include'
    });
    const userC = await response.json();
    return userC;
}

/* Modifier les informations d'un utilisateur */
export async function modifyInfoUser (newInfo) {
    try {
        const response = await fetch(`${ API_USERS }/editInfo`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(newInfo)
        });
        if (response.ok) {
            return ("Information changée avec succès.");
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
    }
}

/* Inscription ou non à la newsletter */
export async function changeNewsLetter (choice, idUser) {
    try {
        await fetch(`${ API_USERS }/newsLetter`, {
            method: "PATCH",
            body: JSON.stringify({ choice, idUser }),
            headers: {
                "Content-Type": "application/json"
            }
        });
    } catch (error) {
        console.error(error);
    }
}

/* Vérifier si l'e-mail renseignée est présente en BDD */
export async function checkEmail (email) {
    try {
        const response = await fetch(`${ API_USERS }/checkEmail`, {
            method: "POST",
            body: JSON.stringify({ email }),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const emailCount = await response.json();
        return emailCount;
    } catch (error) {
        console.error(error);
    }
}

/* Modifier un mot de passe */
export async function modifyPassword (infos) {
    try {
        await fetch(`${ API_USERS }/modifyPassword`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(infos)
        });
    } catch (error) {
        console.error(error);
    }
}
