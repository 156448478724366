import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';

function Footer () {
  return (
    <footer className={`df fdc aic gap3 ${ styles.footer }`}>
      <section className={`df tac ${ styles.section }`}>
        <div className='df fdc aic'>
          <h2 className={`mb10 secondTextColor ${ styles.h2 }`}>* Horaires *</h2>
          <p className='firstTextColor'>Mardi à Samedi :</p>
          <p className='secondTextColor'>11h à 18h</p>
          <p className='firstTextColor'>Dimanche & Lundi :</p>
          <p className='secondTextColor'>Fermé</p>
        </div>
        <div className='df fdc aic'>
          <h2 className={`mb10 secondTextColor ${ styles.h2 }`}>* Contact *</h2>
          <p className={styles.email}><Link title='Envoyer un email a littlekowicz' to="mailto:littlekowicz@outlook.fr?subject=Demande d'informations via le site" className='firstTextColor'>Envoyer un e-mail</Link></p>
        </div>
        <div className='df fdc aic'>
          <h2 className={`mb10 secondTextColor ${ styles.h2 }`}>* Suivez-moi *</h2>
          <div className='df gap2 aic jcc'>
            <p> <Link title="Aller sur le facebook de littlekowicz" to="https://www.facebook.com/LittleKowicz" target='_blank'><i className={`fa-brands fa-facebook-f ${ styles.reseaux } ${ styles.iFacebook } `}></i></Link></p>
            <p> <Link title="Aller sur l'instagram de littlekowicz" to="https://www.instagram.com/littlekowicz/" target='_blank'><i className={`fa-brands fa-instagram ${ styles.reseaux } ${ styles.iInstagram }`}></i></Link></p>
          </div>
        </div>
        <div className='df fdc aic'>
          <h2 className={`mb10 secondTextColor ${ styles.h2 }`}>* Avis *</h2>
          <p><Link title='Aller sur la page google de Littlekowicz' to="https://www.google.fr/search?sa=X&sca_esv=594880089&biw=390&bih=664&q=Littlekowicz&ludocid=15551919372967527457&lsig=AB86z5Ukr__xciUW-pLMVDaBJwJI&kgs=43b9d52f6183d281&shndl=-1&shem=lcspc,lsp&source=sh/x/loc/hdr/m1/3#lrd=0x41b8d95c0752906b:0xd7d38445a93ec421,3,,,," target='_blank' className={styles.reseaux}>Donne ton avis sur google</Link></p>
        </div>
      </section>
      <div className={`${ styles.copyright }`}>
        <p className='df jcc tac aic fw gap1 firstTextColor'>© Littlekowicz 2023 | Tous droits réservés | <span>Réalisé par : <Link title='Aller sur la page linkedin du réalisateur du site' to="https://www.linkedin.com/in/j%C3%A9r%C3%A9my-lefevre-16a64826b/" target='_blank' className={styles.link}>Jérémy</Link></span> | <span> <Link title='Aller sur la page des mentions légales/CGU/Cookies' to="/Mentions" className={styles.link}> Mentions Légales | CGU | Cookies</Link></span></p>
      </div>
    </footer>
  );
}

export default Footer;
