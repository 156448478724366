import { NavLink, Link } from 'react-router-dom';
import styles from './Header.module.scss';
import { useState } from 'react';
import logo from "../../assets/images/logoMulti.webp";
import bulleBleu from "../../assets/images/BullesBleu.webp";
import bulleOrange from "../../assets/images/BullesOrange.webp";
import bulleOrangeRose from "../../assets/images/BullesOrangeRose.webp";
import bulleRose from "../../assets/images/BullesRose.webp";
import bulleVioletRose from "../../assets/images/BullesVioletRose.webp";
import login from "../../assets/images/Login.webp";


function Header () {

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuActive, setMenuActive] = useState(false);

  /* Afficher ou non le contenu du menu burger */
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
    setMenuActive(!menuActive);
  };



  return (
    <header className='df aic'>
      <div className={`${ styles.container } ${ menuVisible ? styles.visible : '' }`}>
        <div onClick={toggleMenu} className={`${ styles.boxBubble } df aic jcc`}>
          <img src={bulleBleu} alt="Bulle de couleur bleu" className={styles.firstBubble} />
          <Link className={`${ styles.bulles1 }`} title="Aller sur la page 'Qui est-elle ?'" to="/presentation">Qui-est-elle ?</Link>
          <img src={bulleRose} alt="Bulle de couleur rose" className={styles.secondBubble} />
        </div>
        <div onClick={toggleMenu} className={`${ styles.boxBubble2 } df aic jcc`}>
          <Link className={`${ styles.bulles2 }`} title="Aller sur la page concours" to='/evenement'>Concours</Link>
          <img src={bulleOrange} alt="Bulle de couleur orange" className={styles.thirdBubble} />
        </div>
        <div onClick={toggleMenu} className={`${ styles.boxBubble4 } df aic jcc`}>
          <Link className={`${ styles.bulles3 }`} title='Aller sur la page des soins' to="/soins">Soins tatouages</Link>
          <img src={bulleOrangeRose} alt="Bulle de couleur orange" className={styles.fourthBubble} />
        </div>
        <div onClick={toggleMenu} className={`${ styles.boxBubble2 } df aic jcc`}>
          <Link className={`${ styles.bulles4 }`} title='Aller sur la page FAQ' to="/faq">F.A.Q</Link>
          <img src={bulleRose} alt="Bulle de couleur orange" className={styles.fifthBubble} />
        </div>
        <div onClick={toggleMenu} className={`${ styles.boxBubble3 } df aic jcc`}>
          <img src={bulleVioletRose} alt="Bulle de couleur orange" className={styles.sixthBubble} />
          <Link className={`${ styles.bulles5 }`} title='Aller sur la page connexion/inscription/profil' to="/forms"><img src={login} alt="icone inscription/connexion" /></Link>
        </div>
      </div>
      <nav className='df aic '>
        <NavLink title="Aller sur la page d'accueil du site" to='/' className={styles.logo}><img src={logo} alt="logo littlekowicz" /></NavLink>
        <ul className={`${ styles.ul }`}>
          <li onClick={toggleMenu}><NavLink title="Aller sur la page présentation" to="/presentation" className="fontNeon">Qui est-elle ?</NavLink></li>
          <li onClick={toggleMenu}><NavLink title='Aller sur la page concours' to="/evenement" className="fontNeon">Concours</NavLink></li>
          <li onClick={toggleMenu}><NavLink title='Aller sur la page des soins pour tatouage' to="/soins" className="fontNeon">Soins tatouage</NavLink></li>
          <li onClick={toggleMenu}><NavLink title='Aller sur la page FAQ' to="/faq" className="fontNeon">FAQ</NavLink></li>
          <li onClick={toggleMenu} className='df aic jcc'><NavLink title='Aller sur la page connexion/inscription/profil' to="/forms"><i className="fa-regular fa-user"></i></NavLink></li>
        </ul>
        <div className={styles.menuBurger}>
          <button onClick={toggleMenu} title="Ouvrir/fermer le menu burger" className={menuActive ? styles.actived : ''}>
            <span className={styles.topLine}></span>
            <span className={styles.middleLine}></span>
            <span className={styles.bottomLine}></span>
          </button>
        </div>
      </nav>
    </header>
  );
}

export default Header;
