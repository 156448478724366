import { createBrowserRouter} from 'react-router-dom';
import { lazy } from 'react';
import App from './App';
import { userLoader } from './loaders/userLoader';
import { ProtectedRouteLogin, ProtectedRouteForms, ProtectedRouteAdmin } from './components/ProtectedRoutes/ProtectedRoute';


const Error = lazy(() => import("./pages/Error/Error"));
const Home = lazy(() => import("./pages/Home/Home"));
const Admin = lazy(() => import("./pages/Admin/Admin"));
const Forms = lazy(() => import("./pages/Forms/Forms"));
const Anime = lazy(() => import("./pages/Anime/Anime"));
const Disney = lazy(() => import("./pages/Disney/Disney"));
const Evenement = lazy(() => import("./pages/Evenement/Evenement"));
const Flash = lazy(() => import("./pages/Flash/Flash"));
const PopCulture = lazy(() => import("./pages/PopCulture/PopCulture"));
const Presentation = lazy(() => import("./pages/Presentation/Presentation"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const Soins = lazy(() => import("./pages/Soins/Soins"));
const Mentions = lazy(() => import("./pages/Mentions/Mentions"));
const Faq = lazy(() => import("./pages/Faq/Faq"));
const Register = lazy(() => import("./pages/Register/Register"));
const Login = lazy(() => import("./pages/Login/Login"));
const ResetPassword = lazy(() => import("./pages/ResetPassword/ResetPassword"));


export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: userLoader,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: "/adminAccess",
        element:
          <ProtectedRouteAdmin>
            <Admin />
          </ProtectedRouteAdmin>
      },
      {
        path: "/forms",
        element:
          <ProtectedRouteForms>
            <Forms />
          </ProtectedRouteForms>,
      },
      {
        path: "/anime",
        element: <Anime />
      },
      {
        path: "/evenement",
        element: <Evenement />
      },
      {
        path: "/disney",
        element: <Disney />
      },
      {
        path: "/flash",
        element: <Flash />
      },
      {
        path: "/popculture",
        element: <PopCulture />
      },
      {
        path: "/presentation",
        element: <Presentation />
      },
      {
        path: "/profile",
        element:
          <ProtectedRouteLogin>
            <Profile />
          </ProtectedRouteLogin>
      },
      {
        path: "/soins",
        element: <Soins />
      },
      {
        path: "/mentions",
        element: <Mentions />
      },
      {
        path: "/faq",
        element: <Faq />
      },
      {
        path: "/register",
        element:
          <ProtectedRouteForms>
            <Register />
          </ProtectedRouteForms>
      },
      {
        path: "/login",
        element:
          <ProtectedRouteForms>
            <Login />
          </ProtectedRouteForms>
      },
      {
        path: '/resetPassword',
        element: <ResetPassword />
      },
      {
        path: "*",
        element: <Error />
      }
    ]
  }
]);