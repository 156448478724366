import { Suspense } from "react";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import AuthProvider from "./components/AuthProvider/AuthProvider";
import { HelmetProvider } from 'react-helmet-async';



function App () {
  const location = useLocation();
  const excludeHeader = location.pathname === "/";

  return (
    <HelmetProvider>
      <AuthProvider>
        {!excludeHeader && <Header />}
        <Suspense>
          <Outlet />
        </Suspense>
        <Footer />
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
